import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lottie from "lottie-react";
import Footer from "../component/Footer";
import pic1 from "../assets/JPEG/SavFront.jpg";
import pic2 from "../assets/JPEG/SavFront2.jpg";
import pic3 from "../assets/JPEG/SavFront3.jpg";
import pic4 from "../assets/JPEG/SavFront4.JPG";
// import pic5 from "../assets/JPEG/SavFront5.JPG";
import pic6 from "../assets/JPEG/SavFront6.jpg";
import pic7 from "../assets/JPEG/SavFront7.JPG";
import pic8 from "../assets/JPEG/SavFront8.jpg";
import pic9 from "../assets/JPEG/SavFront9.jpg";
import pic10 from "../assets/JPEG/SavFront10.jpg";
import pic11 from "../assets/JPEG/SavFront11.jpg";
import pic12 from "../assets/JPEG/SavFront12.jpg";
import pic13 from "../assets/JPEG/SavFront13.jpg";
import pic14 from "../assets/JPEG/SavFront14.jpg";
import pic15 from "../assets/JPEG/SavFront15.jpg";
import pic16 from "../assets/JPEG/SavFront16.jpg";
import pic17 from "../assets/JPEG/SavFront17.jpg";
import pic18 from "../assets/JPEG/SavFront18.jpg";
import pic19 from "../assets/JPEG/SavFront19.jpg";
import pic20 from "../assets/JPEG/SavFront20.jpg";
import pic21 from "../assets/JPEG/SavFront21.jpg";
import pic22 from "../assets/JPEG/SavFront22.JPG";
import pic23 from "../assets/JPEG/SavFront23.JPG";
import pic24 from "../assets/JPEG/SavFront24.JPG";
import pic25 from "../assets/JPEG/SavFront25.JPG";
import pic26 from "../assets/JPEG/SavFront26.JPG";
import pic27 from "../assets/JPEG/SavFront27.JPG";
import pic28 from "../assets/JPEG/SavFront28.JPG";
import pic29 from "../assets/JPEG/SavFront29.JPG";
import pic30 from "../assets/JPEG/SavFront30.jpg";
import pic31 from "../assets/JPEG/SavFront31.jpg";
import pic32 from "../assets/JPEG/SavFront32.jpg";
import pic33 from "../assets/JPEG/SavFront33.jpg";
import pic34 from "../assets/JPEG/SavFront34.jpg";
import headerLottie from "../assets/lottie/Gallery.json";

const Gallery = () => {
  return (
    <div>
      <div className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row  lg:items-end lg:justify-center lg:gap-[2rem] overflow-hidden">
        <section class="bg-white  antialiased">
          <div class="max-w-screen-xl px-4 py-8 mx-auto  lg:py-24">
            <div class="max-w-3xl mx-auto text-center">
              <h1 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 ">
                Gallery
              </h1>
              <Lottie
                className=" mx-auto w-[150px]  lg:w-[200px]"
                animationData={headerLottie}
              />
            </div>
          </div>
          <div class="container mx-auto px-5  lg:px-32  ">
            <div class="-m-1 flex flex-wrap md:-m-2">
              <div class="flex w-1/2 flex-wrap">
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic1}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic6}
                  />
                </div>
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic4}
                  />
                </div>
              </div>
              <div class="flex w-1/2 flex-wrap">
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic10}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic7}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic11}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-5">
            <div class="-m-1 flex flex-wrap md:-m-2">
              <div class="flex w-1/2 flex-wrap">
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic8}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic3}
                  />
                </div>
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic2}
                  />
                </div>
              </div>
              <div class="flex w-1/2 flex-wrap">
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic13}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic12}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic9}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-5">
            <div class="-m-1 flex flex-wrap md:-m-2">
              <div class="flex w-1/2 flex-wrap">
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic14}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic15}
                  />
                </div>
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic16}
                  />
                </div>
              </div>
              <div class="flex w-1/2 flex-wrap">
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic18}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic17}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic19}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-5">
            <div class="-m-1 flex flex-wrap md:-m-2">
              <div class="flex w-1/2 flex-wrap">
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic31}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic20}
                  />
                </div>
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic21}
                  />
                </div>
              </div>
              <div class="flex w-1/2 flex-wrap">
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic22}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic23}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic24}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-5">
            <div class="-m-1 flex flex-wrap md:-m-2">
              <div class="flex w-1/2 flex-wrap">
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic27}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic26}
                  />
                </div>
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic25}
                  />
                </div>
              </div>
              <div class="flex w-1/2 flex-wrap">
                <div class="w-full p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic28}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic29}
                  />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <LazyLoadImage
                    alt="gallery"
                    // effect="blur"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={pic30}
                  />
                </div>
                {/* <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-5">
                  <div class="-m-1 flex flex-wrap md:-m-2">
                    <div class="w-1/2 p-1 md:p-2">
                      <LazyLoadImage
                        alt="gallery"
                        // effect="blur"
                        class="block h-full w-full rounded-lg object-cover object-center"
                        src={pic31}
                      />
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <LazyLoadImage
                        alt="gallery"
                        // effect="blur"
                        class="block h-full w-full rounded-lg object-cover object-center"
                        src={pic32}
                      />
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <LazyLoadImage
                        alt="gallery"
                        // effect="blur"
                        class="block h-full w-full rounded-lg object-cover object-center"
                        src={pic33}
                      />
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <LazyLoadImage
                        alt="gallery"
                        // effect="blur"
                        class="block h-full w-full rounded-lg object-cover object-center"
                        src={pic34}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div class="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36 lg:py-36">
            <span class="font-semibold text-gray-400 uppercase">
              Contact Me
            </span>
            <div class="flex flex-wrap justify-center items-center mt-8 text-gray-500 ">
              <a
                href="https://x.com/savannahmtz_3"
                target="_blank"
                className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  class="h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
                </svg>
              </a>
              <a
                // href="#"
                onClick={() =>
                  (window.location = "mailto:Savannahcmartinez09@gmail.com")
                }
                className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#9575cd"
                  class="h-11"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-11"
                    fill="#9575cd"
                    viewBox="0 0 24 24"
                  ></svg>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/savannahmartinez_3/"
                target="_blank"
                class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-11"
                  fill="#f44336"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              {/* <a
                href="#"
                class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              ></a> */}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
