import React from "react";
import { motion } from "framer-motion";
// import Pdf from "../assets/release-of-liability-activity.pdf";
// import Pdf2 from "../assets/Fundraising Registration Form.jpg";

function ScrollText() {
  return (
    <div class="relative flex overflow-x-hidden ">
      <div class="py-12 animate-marquee whitespace-nowrap">
        <span class="mx-4 text-2xl text-red-500">
          SELECTED 1ST TEAM CATCHER FOR BIG 7 CONFERENCE
        </span>
        <span class="mx-4 text-2xl text-red-500">
          SELECTED 1ST TEAM CATCHER FOR BIG 7 CONFERENCE
        </span>
      </div>

      <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
        <span class="mx-4 text-2xl text-red-500">
          SELECTED 1ST TEAM CATCHER FOR BIG 7 CONFERENCE{" "}
        </span>
        <span class="mx-4 text-2xl text-red-500">
          SELECTED 1ST TEAM CATCHER FOR BIG 7 CONFERENCE{" "}
        </span>
      </div>
    </div>
  );
}

export default ScrollText;
