import Highlights from "./page/Highlights.jsx";
import Gallery from "./page/Gallery.jsx";
import Contact from "./page/Contact";
import Home from "./page/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./component/Header";

import Schedule from "./page/Schedule.jsx";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/highlights" element={<Highlights />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
