import React from "react";

import Hero from "../component/Hero";
import Hero2 from "../component/Hero2";

import Footer from "../component/Footer";
import ScrollText from "../component/ScrollText";

const Home = () => {
  return (
    <div>
      <Hero />
      <div className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row  lg:items-end lg:justify-center lg:gap-[2rem] overflow-hidden">
        <ScrollText />
      </div>
      <Hero2 />

      <Footer />
    </div>
  );
};

export default Home;
