import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import pic1 from "../assets/JPEG/SavFront.jpg";

import pic4 from "../assets/JPEG/SavFront4.JPG";
import pic6 from "../assets/JPEG/SavFront6.jpg";
import pic7 from "../assets/JPEG/SavFront7.JPG";

import pic10 from "../assets/JPEG/SavFront10.jpg";
import pic11 from "../assets/JPEG/SavFront11.jpg";
import ScrollText from "../component/ScrollText.jsx";

function Hero() {
  return (
    <div className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row  lg:items-end lg:justify-center lg:gap-[2rem] overflow-hidden">
      <section class="bg-white">
        <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-1">
          <div class="-m-1 flex flex-wrap md:-m-2">
            <div class="flex w-1/2 flex-wrap">
              <div class="w-1/2 p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic1}
                />
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic6}
                />
              </div>
              <div class="w-full p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic4}
                />
              </div>
            </div>
            <div class="flex w-1/2 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic10}
                />
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic7}
                />
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <LazyLoadImage
                  alt="gallery"
                  // effect="blur"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={pic11}
                />
              </div>
            </div>
          </div>

          <div class="max-w-3xl mx-auto text-center p-5">
            <a
              href="/gallery"
              class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-black hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              Gallery
              <svg
                class="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
