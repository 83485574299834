import React from "react";
import headerLottie from "../assets/JPEG/SavFront18.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lottie from "lottie-react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Testimonies() {
  return (
    <div>
      <div className="flex flex-col overflow-auto justify-center items-center px-5 xl:flex-row  lg:items-end lg:justify-center lg:gap-[2rem] overflow-hidden">
        <section class="bg-white  antialiased">
          <div class="max-w-screen-xl px-4 py-8 mx-auto  lg:py-24 ">
            <div class="max-w-3xl mx-auto text-center">
              <h1 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 pb-5">
                About Me
              </h1>

              <LazyLoadImage
                alt="gallery"
                class="mx-auto w-[350px]  lg:w-[650px] block h-full w-full rounded-lg object-center"
                // className=" mx-auto w-[150px]  lg:w-[200px]"
                src={headerLottie}
              />
            </div>

            <div class="flow-root text-center text-1xl max-w-3xl mx-auto mt-3 sm:mt-12 lg:mt-8">
              <p>
                I am deeply passionate about softball, and it has been an
                integral part of my life since I was just four years old. The
                joy of stepping onto the diamond and feeling the rush of
                excitement during every game is indescribable. Over the years,
                my dedication and hard work have allowed me to develop my skills
                and become a strong player. As I progress through my softball
                journey, my ultimate aspiration is to play at the college level,
                where I can challenge myself further and continue to pursue my
                love for the sport. Softball has taught me valuable life lessons
                about teamwork, perseverance, and resilience, shaping me into
                not only a better player but also a more well-rounded
                individual. With unwavering determination, I am excited to see
                where this incredible sport will take me in the future.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Testimonies;
