import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
// import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import Swal from "sweetalert2";
import headerLottie from "../assets/lottie/Contact.json";
import Lottie from "lottie-react";

export const ContactComponent = () => {
  const form = useRef();

  const handleSubmit = (event) => {
    document.getElementById("my_form").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // handleSubmit();

    emailjs
      .sendForm(
        "service_4iuzwho",
        "template_3kz2d8b",
        form.current,
        "OdfhtH4RHQ2c5kd7q"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: `<h5 style='color:green'>Message Sent Successfully</h5>`,
            confirmButtonColor: "green",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: `<h5 style='color:red'>Ooops, something went wrong</h5>`,
            confirmButtonColor: "red",
          });
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <section className=" text-gray-700  body-font relative">
        <div className="container px-5 py-5 mx-auto ">
          <div class="max-w-3xl mx-auto text-center">
            <h1 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 ">
              Contact Me
            </h1>
            <Lottie
              className=" mx-auto w-[150px]  lg:w-[200px]"
              animationData={headerLottie}
            />
          </div>
          <div className="flex flex-col text-center w-full mb-12"></div>
          <form id="my_form" ref={form} onSubmit={sendEmail}>
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div for="name" className="relative">
                    <input
                      type="name"
                      id="name"
                      name="name"
                      placeholder="Your Name"
                      required
                      className="w-full  rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div for="email" className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                      required
                      className="w-full  rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div for="message" className="relative">
                    <textarea
                      id="message"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                      className="w-full rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button
                    onclick="sendmail()"
                    type="submit"
                    onChange={handleSubmit}
                    class="w-full px-4 py-2 mt-2 font-medium text-center text-white bg-black transition-colors duration-200 rounded-md bg-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1"
                  >
                    Send Message
                  </button>
                </div>

                <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                  <a className="text-black">Savannahcmartinez09@gmail.com</a>
                  <p className="leading-normal my-5">
                    Putnam City North
                    <br />
                    #3
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <span class="font-semibold text-gray-400 uppercase">Contact Me</span>
          <div class="flex flex-wrap justify-center items-center mt-8 text-gray-500 ">
            <a
              href="https://x.com/savannahmtz_3"
              target="_blank"
              className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                class="h-10"
                viewBox="0 0 24 24"
              >
                <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
              </svg>
            </a>
            <a
              // href="#"
              onClick={() =>
                (window.location = "mailto:Savannahcmartinez09@gmail.com")
              }
              class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#9575cd"
                class="h-11"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-11"
                  fill="#9575cd"
                  viewBox="0 0 24 24"
                ></svg>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/savannahmartinez_3/"
              target="_blank"
              class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11"
                fill="#f44336"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
            {/* <a
                href="#"
                class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              ></a> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactComponent;
