import React from "react";

import ContactComponent from "../component/ContactComponent";
import Testimonies from "../component/Testimonies";
import Footer from "../component/Footer";

const Contact = () => {
  return (
    <div>
      <Testimonies />
      <ContactComponent />

      <Footer />
    </div>
  );
};

export default Contact;
